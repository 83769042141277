import { createNewPortfolioApp } from '@wix/ambassador-portfolio-projects-v1-project/build/es/http';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getPortfolioApi = (httpClient: IHttpClient) => {
  const notifyPortfolioAppCreation = async () => {
    try {
      const data = await httpClient.request(createNewPortfolioApp({}));
      return data;
    } catch (e) {}
  };
  return { notifyPortfolioAppCreation };
};
