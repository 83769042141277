import { defaultHelpId } from '../../constants';

export const configurePages = (pagesBuilder, t) => {
  // the actions for each page
  pagesBuilder.set({
    icon: 'page',
  });

  // specific page actions according to page state
  pagesBuilder.configureState('Portfolio', (stateBuilder) => {
    stateBuilder.set({
      icon: 'page',
      tooltip: t('Page_Builder_Portfolio_Page_Tooltip'),
      orderIndex: 2,
    });
    stateBuilder
      .addAction(
        {
          type: 'page_rename',
        },
        {
          type: 'page_set_as_homepage',
        },
      )
      .addAction({
        title: t('Page_Builder_Settings_Tab_Action_Delete_App'),
        icon: 'delete',
        event: 'deletePortfolioApp',
      });
  });
  pagesBuilder.configureState('Collection', (stateBuilder) => {
    stateBuilder.set({
      icon: 'page',
      tooltip: t('Page_Builder_Collection_Page_Tooltip'),
      orderIndex: 1,
    });
  });
  pagesBuilder.configureState('Project', (stateBuilder) => {
    stateBuilder.set({
      icon: 'page',
      tooltip: t('Page_Builder_Project_Page_Tooltip'),
      orderIndex: 0,
    });
  });

  // the tab settings for each page that opens when clicking settings/seo.. tab
  pagesBuilder.addSettingsTab(
    {
      type: 'page_info',
    },
    {
      type: 'layout',
    },
    {
      type: 'permissions',
    },
  );
};
export const configPagesTabs = (pagesTabBuilder, t) => {
  // application page settings
  pagesTabBuilder
    .set({
      helpId: defaultHelpId,
      displayName: t('Pages_Tab_Builder_App_Name'),
    })
    .setGrouping('none');

  // application page settings actions
  // pagesTabBuilder.addAction({
  //   title: t('Page_Builder_Settings_Tab_Action_Delete_App'),
  //   icon: 'delete',
  //   event: 'deletePortfolioApp',
  // });
};

export const getPortfolioPageSettings = (t: any) => [
  {
    type: 'page_info',
  },
  {
    type: 'layout',
  },
  {
    type: 'permissions',
  },
  {
    title: t('Page_Builder_Settings_Tab_Page_Seo'),
    type: 'seo',
  },
];
