import { EditorSDK } from '@wix/platform-editor-sdk';
import _ from 'lodash';

const PORTFOLIO_APP_DEF_ID = 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130';

export async function setPortfolioPagesStates(editorSDK: EditorSDK) {
  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  const portfolioPages = portfolioPagesData.filter(
    ({ managingAppDefId }) => managingAppDefId === PORTFOLIO_APP_DEF_ID,
  );
  portfolioPages.forEach((pageData) => {
    editorSDK.document.pages.setState('', {
      state: {
        // @ts-ignore
        [pageData.tpaPageId]: [{ id: pageData.id }],
      },
    });
  });
}

export async function updatePagesDataOnRouter(editorSDK: EditorSDK) {
  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  const siteRouters = await editorSDK.routers.getAll('');
  const portfolioRouter = _.find(siteRouters, (router) =>
    _.has(router, 'config.portfolioPagesData'),
  );
  if (portfolioRouter) {
    const routerRef = await editorSDK.routers.getByPrefix('', {
      prefix: portfolioRouter?.prefix as string,
    });
    await editorSDK.routers.update('', {
      routerRef,
      config: { portfolioPagesData },
    });
  }
}
