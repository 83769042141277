import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../editor-app/manifest/getRole';
import {
  ProjectHeaderWidgetComponentIds,
  ProjectHeaderWidgetPresets,
} from './config/constants';
import { defaultHelpId } from '../../constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Project_Header_Widget'),
    nickname: 'projectHeaderWidget',
  });
  controllerBuilder
    .gfpp()
    .set('add', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });

  // control connected components
  controllerBuilder.configureConnectedComponents(
    'imageBox',
    (componentBuilder) => {
      componentBuilder
        .gfpp()
        .set('design', {
          target: {
            role: 'projectHeroImage',
          },
        })
        .set('animation', {
          target: {
            role: 'projectHeroImage',
          },
        });
    },
  );

  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('Project_Header_Widget_Design_Panel_Title'),
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignBuilder.setPresets(ProjectHeaderWidgetPresets);
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Project_Design_Panel_Header_Background'),
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.Root].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Project_Design_Panel_Title'),
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.Title].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Project_Design_Panel_Details_Label'),
          })
          .groups()
          .set({
            roles: ['#detailLabel'].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Project_Design_Panel_Details_Text'),
          })
          .groups()
          .set({
            roles: ['#detailText'].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Project_Design_Panel_Description'),
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.Description].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Project_Design_Panel_Hero_Image'),
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.HeroImage].map(getRole),
          });
      });
  });
};
