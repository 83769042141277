import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { CollectionWidgetComponentIds } from '../collectionWidget/config/constants';
import { NavigationWidgetComponentIds } from '../navigationWidget/config/constants';
import { getRole } from '../../editor-app/manifest/getRole';
import { defaultHelpId } from '../../constants';
import { CollectionPageWidgetPresets } from './config/constants';
import { refreshAppServices } from '../../utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  const enableCollectionPagePresets = flowAPI.experiments.enabled(
    'specs.portfolio.enableCollectionPagePresets',
  );
  controllerBuilder.set({
    displayName: t('Widget_Name_Collections_Page_Widget'),
    nickname: 'collectionPageWidget ',
  });
  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Collection_Page_Widget_Gfpp_Main_Action_1'),
      onClick: () => {
        void editorSDK.editor
          .openDashboardPanel('', {
            url: '/portfolio/collections',
            closeOtherPanels: false,
          })
          .then(refreshAppServices(editorSDK));
      },
    })
    .set('mainAction2', {
      label: t('Collection_Page_Widget_Gfpp_Main_Action_2'),
      onClick: () => {
        void editorSDK.editor
          .openDashboardPanel('', {
            url: '/portfolio/collection',
            closeOtherPanels: false,
          })
          .then(refreshAppServices(editorSDK));
      },
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', { actionId: 'openElementsPanel' })
    .set('help', { id: defaultHelpId });

  controllerBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('Collection_Page_Widget_Gfpp_Main_Action_1'),
      onClick: () => {
        void editorSDK.editor
          .openDashboardPanel('', {
            url: '/portfolio/collections',
            closeOtherPanels: false,
          })
          .then(refreshAppServices(editorSDK));
      },
    })
    .set('mainAction2', {
      label: t('Collection_Page_Widget_Gfpp_Main_Action_2'),
      onClick: () => {
        void editorSDK.editor
          .openDashboardPanel('', {
            url: '/portfolio/collection',
            closeOtherPanels: false,
          })
          .then(refreshAppServices(editorSDK));
      },
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });

  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    const designBuilder = widgetDesignBuilder.set({
      title: t('Project_Page_Widget_Design_Panel_Title'),
      presetsTitle: t('Project_Page_Widget_Design_Panel_Preset_Title'),
    });

    const widgetDesignTabsBuilder = designBuilder.tabs();
    enableCollectionPagePresets &&
      designBuilder.setPresets(CollectionPageWidgetPresets);
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Collection_Design_Panel_Title'),
          })
          .groups()
          .set({
            roles: [CollectionWidgetComponentIds.Title].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Collection_Design_Panel_Description'),
          })
          .groups()
          .set({
            roles: [CollectionWidgetComponentIds.Description].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Collection_Design_Panel_Projects_Gallery'),
          })
          .groups()
          .set({
            roles: [CollectionWidgetComponentIds.Gallery].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Collection_Design_Panel_Back_Button'),
          })
          .groups()
          .set({
            roles: [NavigationWidgetComponentIds.BackButtonWidget].map(getRole),
          });
      });
  });
};
