import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { NavigationWidgetComponentIds } from './config/constants';
import { getRole } from '../../editor-app/manifest/getRole';
import { defaultHelpId } from '../../constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Navigation_Widget'),
    nickname: 'navigationWidget',
  });

  controllerBuilder
    .gfpp()
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' })
    .set('add', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });

  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    const designBuilder = widgetDesignBuilder.set({
      title: t('Project_Page_Widget_Design_Panel_Title'),
      presetsTitle: t('Project_Page_Widget_Design_Panel_Preset_Title'),
    });

    // const widgetDesignTabsBuilder = designBuilder.tabs();
    // widgetDesignTabsBuilder.addTab((tabBuilder) => {
    //   tabBuilder
    //     .set({
    //       label: 'Buttons',
    //     })
    //     .groups()
    //     .set({
    //       roles: [
    //         NavigationWidgetComponentIds.BackButtonWidget,
    //         NavigationWidgetComponentIds.NextButtonWidget,
    //       ].map(getRole),
    //     });
    // });
  });
};
