import _ from 'lodash';
import { getPortfolioPageSettings } from './configurePages';

export const setCustomManifest = (
  appManifest: any,
  t: any,
  collectionsLength: number,
) => {
  appManifest.pages.pageSettings.Portfolio = getPortfolioPageSettings(t);
  if (collectionsLength > 1) {
    appManifest.controllersStageData[
      'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-n0thf'
    ].default.gfpp.desktop.iconButtons.add = { behavior: 'HIDE' };
    appManifest.controllersStageData[
      'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-n0thf'
    ].default.gfpp.desktop.widgetDesign.tabs = [
      {
        label: t('Portfolio_Design_Panel_Projects_Gallery'),
        groups: {
          roles: ['collectionsGallery'],
        },
      },
    ];
  }
  // // add Portfolio page's mobile design panel
  // appManifest.controllersStageData[
  //   'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-n0thf'
  // ].default.gfpp.mobile.widgetDesign = _.cloneDeep({
  //   ...appManifest.controllersStageData[
  //     'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-n0thf'
  //   ].default.gfpp.desktop.widgetDesign,
  //   presets: undefined,
  // });

  // // add Collection page's mobile design panel
  // appManifest.controllersStageData[
  //   'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-voiu8'
  // ].default.gfpp.mobile.widgetDesign = _.cloneDeep({
  //   ...appManifest.controllersStageData[
  //     'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-voiu8'
  //   ].default.gfpp.desktop.widgetDesign,
  //   presets: undefined,
  // });

  // // add Project page's mobile design panel
  // appManifest.controllersStageData[
  //   'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-yaafe'
  // ].default.gfpp.mobile.widgetDesign = _.cloneDeep({
  //   ...appManifest.controllersStageData[
  //     'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-yaafe'
  //   ].default.gfpp.desktop.widgetDesign,
  //   presets: undefined,
  // });
};
