import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../editor-app/manifest/getRole';
import { CollectionWidgetComponentIds } from '../collectionWidget/config/constants';
import { defaultHelpId } from '../../constants';
import { refreshAppServices } from '../../utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Portfolio_Page_Widget'),
    nickname: 'portfolioPageWidget',
  });

  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_1'),
      onClick: () => {
        editorSDK.editor
          .openDashboardPanel('', {
            url: '/portfolio/projects',
            closeOtherPanels: false,
          })
          .then(refreshAppServices(editorSDK));
      },
    })
    .set('mainAction2', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_2'),
      onClick: () => {
        editorSDK.editor
          .openDashboardPanel('', {
            url: '/portfolio/collections?navToCollectionIfNeeded=true',
            closeOtherPanels: false,
          })
          .then(refreshAppServices(editorSDK));
      },
    })
    .set('layout', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('add', { actionId: 'openElementsPanel' })
    .set('help', { id: defaultHelpId });

  controllerBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_1'),
      onClick: () => {
        editorSDK.editor
          .openDashboardPanel('', {
            url: '/portfolio/projects',
            closeOtherPanels: false,
          })
          .then(refreshAppServices(editorSDK));
      },
    })
    .set('mainAction2', {
      label: t('Portfolio_Page_Widget_Gfpp_Main_Action_2'),
      onClick: () => {
        editorSDK.editor
          .openDashboardPanel('', {
            url: '/portfolio/collections?navToCollectionIfNeeded=true',
            closeOtherPanels: false,
          })
          .then(refreshAppServices(editorSDK));
      },
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });

  // define widget design panel
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('Portfolio_Page_Widget_Design_Panel_Title'),
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Portfolio_Design_Panel_Title'),
          })
          .groups()
          .set({
            roles: [CollectionWidgetComponentIds.Title].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Portfolio_Design_Panel_Description'),
          })
          .groups()
          .set({
            roles: [CollectionWidgetComponentIds.Description].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t('Portfolio_Design_Panel_Projects_Gallery'),
          })
          .groups()
          .set({
            roles: [CollectionWidgetComponentIds.Gallery].map(getRole),
          });
      });
  });
};
