import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { getPanelData } from './panelUtils';

export const openElementsPanel = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  t: any,
): Promise<void> => {
  const { categoriesData, elementsData } = await getPanelData(editorSDK, t);
  const getCollapsedRefComponentByRole = async (role) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponents = await editorSDK.components.refComponents.getCollapsedRefComponents(
      'token',
      {
        componentRef: widgetRefHost,
        // @ts-ignore
        includeInnerCollapsed: true,
      },
    );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );
    return collapsedRefComponent[0].componentRef;
  };

  const getCompToHide = async (componentRef) => {
    const type = await editorSDK.components.getType('token', { componentRef });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComp = async (componentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
    return editorSDK.application.livePreview.refresh('token', {
      shouldFetchData: false,
      source: 'AFTER_DB_CHANGE',
    });
  };

  const hideComp = async (componentRef) => {
    await editorSDK.components.refComponents.collapseReferredComponent(
      'token',
      {
        componentRef,
      },
    );
  };

  const addCompHandler = async ({ role }) => {
    const componentRef = await getCollapsedRefComponentByRole(role);
    return showComp(componentRef);
  };

  const removeCompHandler = async (compRef) => {
    const compToHide = await getCompToHide(compRef);
    return hideComp(compToHide);
  };

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    categoriesData,
    elementsData,
    addComponentHandler: addCompHandler,
    removeComponentHandler: removeCompHandler,
  });
};
