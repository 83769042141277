import type { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';
import { Pages, PortfolioPagesRole } from './const/pagesConsts';
import {
  createAppPage,
  initPortfolioPrefix,
  addPageToRouter,
} from './utils/installationUtils';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPortfolioApi } from '../../services/getPortfolioApi';
import { portfolioAddedToStage } from '@wix/bi-logger-portfolio-users/v2';

export async function handleFirstInstall(
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> {
  const { t } = flowAPI.translations;
  const biLogger = options.essentials.biLoggerFactory().logger();
  biLogger.report(portfolioAddedToStage({}));
  const { notifyPortfolioAppCreation } = getPortfolioApi(flowAPI.httpClient);
  await notifyPortfolioAppCreation();
  const isResponsive = options.origin.type === 'RESPONSIVE';
  const portfolioPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.PORTFOLIO_PAGE,
    isResponsive,
    shouldAddMenuItem: true,
    shouldNavigateToPage: false,
    t,
  });
  const collectionPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.COLLECTION_PAGE,
    isResponsive,
    t,
  });
  const projectPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.PROJECT_PAGE,
    isResponsive,
    t,
  });

  // will be open later
  // const tagPageRef = await createAppPage({
  //   editorSDK,
  //   appDefId,
  //   pageData: Pages.TAG_PAGE,
  //   isResponsive,
  // });

  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  const portfolioRouterRef = await initPortfolioPrefix(
    editorSDK,
    portfolioPagesData,
  );
  await addPageToRouter(
    editorSDK,
    portfolioRouterRef,
    collectionPageRef,
    PortfolioPagesRole.Collection,
  );
  await addPageToRouter(
    editorSDK,
    portfolioRouterRef,
    projectPageRef,
    PortfolioPagesRole.Project,
  );
  await editorSDK.pages.navigateTo('', { pageRef: portfolioPageRef });
  // await addPageToRouter(
  //   editorSDK,
  //   portfolioRouterRef,
  //   tagPageRef,
  //   PortfolioPagesRole.Tag,
  // );
}
