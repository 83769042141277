import { EditorSDK } from '@wix/platform-editor-sdk';
import { getProjectElementsData } from '../components/projectPageWidget/panel/showHideData';
import { getCollectionElementsData } from '../components/collectionPageWidget/panel/showHideData';
import { getPortfolioElementsData } from '../components/portfolioPageWidget/panel/showHideData';

export const getCurrentPageTpaId = async (editorSDK: EditorSDK) => {
  const pageRef = await editorSDK.pages.getCurrent('');
  const currentPageData = await editorSDK.pages.data.get('', { pageRef });
  return currentPageData.tpaPageId;
};

export const getPanelData = async (editorSDK: EditorSDK, t: any) => {
  const currentPageTpaId = await getCurrentPageTpaId(editorSDK);
  let panelData = {
    categoriesData: [] as any,
    elementsData: [] as any,
  };
  switch (currentPageTpaId) {
    case 'Portfolio':
      panelData = getPortfolioElementsData(t);
      break;
    case 'Collection':
      panelData = getCollectionElementsData(t);
      break;
    case 'Project':
      panelData = getProjectElementsData(t);
      break;

    default:
      break;
  }
  return panelData;
};
