import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Navigation_Button_Widget'),
    nickname: 'navigationButtonWidget',
  });
  controllerBuilder.gfpp().set('add', { behavior: 'HIDE' });
};
