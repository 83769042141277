import { EditorSDK, AppManifest } from '@wix/platform-editor-sdk';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { configurePages, configPagesTabs } from './manifest/configurePages';
import { configureManagementActions } from './manifest/configureManagementActions';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import { setCustomManifest } from './manifest/customizeManifest';

export const modifyAppManifest = async (
  options,
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const { t } = flowAPI.translations;
  const enableAddPortfolioWidgetButton = flowAPI.experiments.enabled(
    'specs.portfolio.enableAddPortfolioWidgetButton',
  );
  const baseManifest = await fetchWidgetsStageData(options);
  const pages = (pagesBuilder) => configurePages(pagesBuilder, t);
  const pagesTab = (pagesTabBuilder) => configPagesTabs(pagesTabBuilder, t);
  const managementActions = (managementActionsBuilder) =>
    configureManagementActions(
      managementActionsBuilder,
      t,
      enableAddPortfolioWidgetButton,
    );

  const collectionsLength = await flowAPI.httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: false },
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections?.length;
    })
    .catch((e) => {
      console.error(e);
      return 0;
    });
  appManifestBuilder
    .withJsonManifest(baseManifest)
    .configurePages(pages)
    .configurePagesTab(pagesTab)
    .configureManagementActions(managementActions);
  const appManifest = appManifestBuilder.build();
  setCustomManifest(appManifest, t, collectionsLength);
  return appManifest;
};
